import axios from 'axios';
import { manifestApiBaseUrl } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: manifestApiBaseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.response.data.error === 'invalid_token') {
      return Promise.reject(error);
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export const baseAxios = axios;

export default axiosInstance;
