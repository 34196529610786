import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isSentMfaToken, setRedirect, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  console.log('auth guard', pathname, !isAuthenticated && isSentMfaToken);

  if (!isAuthenticated && !isSentMfaToken) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
      setRedirect(pathname);
    }
    return <Login />;
  }

  if (!isAuthenticated && isSentMfaToken) {
    return <Navigate to="/auth/verify" />;
  }

  if (user && !user.emailVerified) {
    return <Navigate to="/auth/verify-email" />;
  }

  if (requestedLocation && pathname !== '/') {
    setRequestedLocation(null);
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
