// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { selectedEnterpriseConnection } = useAuth();
  const selectedEnterpriseProfile = selectedEnterpriseConnection.enterpriseProfile;
  return (
    <MAvatar
      src={selectedEnterpriseProfile?.avatar}
      alt={selectedEnterpriseProfile?.avatarKey}
      color={selectedEnterpriseProfile?.avatar ? 'default' : createAvatar(selectedEnterpriseProfile?.displayName).color}
      {...other}
    >
      {createAvatar(selectedEnterpriseProfile?.displayName).name}
    </MAvatar>
  );
}
