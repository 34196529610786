// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/static/icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />;
const getNavbarIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);
const ICONS = {
  blog: getNavbarIcon('ic_blog'),
  cart: getNavbarIcon('ic_cart'),
  chat: getNavbarIcon('ic_chat'),
  mail: getNavbarIcon('ic_mail'),
  user: getNavbarIcon('ic_user'),
  users: getNavbarIcon('ic_users'),
  kanban: getNavbarIcon('ic_kanban'),
  banking: getNavbarIcon('ic_banking'),
  calendar: getNavbarIcon('ic_calendar'),
  ecommerce: getNavbarIcon('ic_ecommerce'),
  analytics: getNavbarIcon('ic_analytics'),
  dashboard: getNavbarIcon('ic_dashboard'),
  booking: getNavbarIcon('ic_booking'),
  key: getNavbarIcon('ic_key'),
  clearAll: getNavbarIcon('ic_clear_all'),
  home: getNavbarIcon('ic_home'),
  components: getNavbarIcon('ic_components'),
  authenticator: getNavbarIcon('ic_authenticator'),
  code: getIcon('ic_code'),
  shield: getIcon('shield')
};

const configSidebarForEnv = (config) => {
  const env = process.env.REACT_APP_BUILD_ENV;
  return config
    .filter((item) => !item.enviroment || item.enviroment.includes(env))
    .map((c) => ({
      ...c,
      items: c.items ? configSidebarForEnv(c.items) : []
    }));
};
// ! if changing enviroment attribute, change it in src/routes/index.js too
const sidebarConfig = [
  // ENTERPRISE
  // ----------------------------------------------------------------------
  {
    subheader: 'enterprise',
    items: [
      {
        title: 'Overview',
        path: PATH_DASHBOARD.enterprise.overview,
        icon: ICONS.home,
        grantAccessTo: ['isAdmin', 'hasBankingAccess']
      },
      { title: 'Users', path: PATH_DASHBOARD.enterprise.users, icon: ICONS.users, grantAccessTo: ['isAdmin'] }
      // {
      //   title: 'Affiliate Program',
      //   path: PATH_DASHBOARD.enterprise.affiliateProgram,
      //   icon: ICONS.banking,
      //   enviroment: ['production'],
      //   grantAccessTo: ['isAdmin', 'hasBankingAccess']
      // }
    ]
  },

  // CONNECT
  // ----------------------------------------------------------------------
  {
    subheader: 'connect',
    items: [
      {
        title: 'Payouts',
        path: PATH_DASHBOARD.connect.requestPayout,
        icon: ICONS.clearAll,
        grantAccessTo: ['isAdmin', 'hasBankingAccess'],
        renderIf: {
          Or: ['hasNoDashPayoutAccess']
        }
      },
      {
        title: 'Creators',
        path: PATH_DASHBOARD.connect.creators,
        icon: ICONS.user,
        grantAccessTo: ['isAdmin', 'hasBankingAccess'],
        renderIf: {
          Or: ['hasDashPayoutAccess']
        }
      },
      // {
      //   title: 'Bulk Payouts',
      //   path: PATH_DASHBOARD.connect.bulkPayments,
      //   icon: ICONS.clearAll,
      //   grantAccessTo: ['isAdmin', 'hasBankingAccess'],
      //   renderIf: {
      //     Or: ['hasDashPayoutAccess']
      //   }
      // },
      {
        title: 'API',
        path: PATH_DASHBOARD.connect.api,
        icon: ICONS.code,
        grantAccessTo: ['isAdmin', 'isDeveloper']
      }
      // {
      //   title: 'Integrations',
      //   path: PATH_DASHBOARD.connect.integrations,
      //   icon: ICONS.components,
      //   grantAccessTo: ['isAdmin', 'hasBankingAccess']
      // }
    ]
  },
  {
    subheader: 'My Account',
    items: [
      {
        title: 'Notifications',
        path: PATH_DASHBOARD.settings.notifications,
        icon: ICONS.mail,
        grantAccessTo: ['isAdmin', 'hasBankingAccess']
      },
      {
        title: 'Security',
        path: PATH_DASHBOARD.settings.twoFa,
        icon: ICONS.shield
      }
    ]
  }
];

export default configSidebarForEnv(sidebarConfig);
