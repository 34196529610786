import { createContext, useEffect, useReducer } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// utils
import { Base64 } from 'js-base64';
import querystring from 'querystring';
import { isValidToken, setSession } from '../utils/jwt';
import axios, { baseAxios } from '../utils/axios';
import { isSandbox, isProd } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isSentMfaToken: false,
  user: null,
  mobileLast4: null,
  rememberMe: true,
  from: null,
  enterpriseUserConnection: [],
  selectedEnterpriseConnection: {},
  inviteToken: null,
  redirectUrl: null,
  twoFaMethod: '',
  twoFaEnabled: false
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, enterpriseUserConnection, selectedEnterpriseConnection } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isSentMfaToken: false,
      user,
      enterpriseUserConnection,
      selectedEnterpriseConnection
    };
  },
  LOGIN: (state, action) => {
    const { user, mobileLast4, rememberMe, inviteToken, twoFaMethod, twoFaEnabled } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      isSentMfaToken: true,
      mobileLast4,
      user,
      rememberMe,
      inviteToken,
      from: 'Login',
      twoFaMethod,
      twoFaEnabled
    };
  },
  SWITCH_BUSINESS_PROFILE: (state, action) => {
    const { selectedEnterpriseConnection } = action.payload;
    return { ...state, selectedEnterpriseConnection };
  },
  MFA_CONFIRM: (state, action) => {
    // console.log('MFA', action.payload);
    const { user, enterpriseUserConnection, selectedEnterpriseConnection, inviteToken } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isSentMfaToken: true,
      user,
      enterpriseUserConnection,
      selectedEnterpriseConnection,
      inviteToken
    };
  },
  VERIFY_EMAIL_SUCCESS: (state, action) => {
    const { user, enterpriseUserConnection, selectedEnterpriseConnection } = action.payload;
    return {
      ...state,
      inviteToken: null,
      user,
      enterpriseUserConnection,
      selectedEnterpriseConnection
    };
  },
  LOGOUT: (state, action) => ({
    ...state,
    isAuthenticated: false,
    isSentMfaToken: false,
    user: null,
    inviteToken: null,
    redirectUrl: action?.payload?.redirectUrl || null
  }),
  REGISTER: (state, action) => {
    const { user, mobileLast4, inviteToken, twoFaMethod, twoFaEnabled } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      isSentMfaToken: true,
      mobileLast4,
      user,
      from: 'Register',
      inviteToken,
      twoFaMethod,
      twoFaEnabled
    };
  },
  MODIFY_MOBILE: (state, action) => {
    const { mobileLast4 } = action.payload;
    return {
      ...state,
      mobileLast4
    };
  },
  UPDATE_USER_PHONE: (state, action) => {
    const { newMobileNumber } = state;
    return {
      ...state,
      user: {
        ...state.user,
        mobileNumber: newMobileNumber
      },
      newMobileNumber: null
    };
  },
  SET_NEW_MOBILE_NUMBER: (state, action) => {
    const { newMobileNumber } = action.payload;
    return {
      ...state,
      newMobileNumber
    };
  },
  MODIFY_EMAIL: (state, action) => {
    const { email } = action.payload;
    return {
      ...state,
      user: {
        ...state.user,
        email
      }
    };
  },
  REDIRECT: (state, action) => ({
    ...state,
    redirectUrl: null
  }),
  SET_REDIRECT_URL: (state, action) => ({
    ...state,
    redirectUrl: action.payload.redirectUrl
  }),
  UPDATE_MFA_METHOD: (state, action) => {
    const { twoFaMethod } = action.payload;
    return {
      ...state,
      twoFaMethod,
      user: {
        ...state.user,
        twoFaMethod
      }
    };
  },
  UPDATE_EMAIL: (state, action) => {
    const { email } = action.payload;
    return {
      ...state,
      user: {
        ...state.user,
        email
      }
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401 && error?.response?.data?.error === 'invalid_token') {
        setSession(null);
        dispatch({
          type: 'LOGOUT',
          payload: {
            redirectUrl: pathname
          }
        });
        navigate('/auth/login');
      } else {
        return Promise.reject(error);
      }
    }
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);

      const response = await axios.get('/user/profile');
      const enterpriseProfileResponse = await axios.get('/banking/enterprise/list');
      const user = response.data;
      const { list } = enterpriseProfileResponse.data;
      const selectedEnterpriseConnection = list.find(
        (connection) => connection?.enterpriseProfile.createdBy === user._id
      );

      return {
        isAuthenticated: true,
        isSentMfaToken: true,
        user,
        enterpriseUserConnection: list,
        selectedEnterpriseConnection: selectedEnterpriseConnection || (list[0] ?? {})
      };
    }
    return {
      isAuthenticated: false,
      isSentMfaToken: false,
      user: null,
      enterpriseUserConnection: [],
      selectedEnterpriseConnection: {}
    };
  };

  const initialize = async () => {
    try {
      const { isAuthenticated, isSentMfaToken, user, enterpriseUserConnection, selectedEnterpriseConnection } =
        await getData();

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated,
          isSentMfaToken,
          user,
          enterpriseUserConnection,
          selectedEnterpriseConnection
        }
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          isSentMfaToken: false,
          user: null,
          enterpriseUserConnection: [],
          selectedEnterpriseConnection: {}
        }
      });
    }
  };

  useEffect(() => {
    (async () => {
      await initialize();
    })();
  }, []);

  const login = async (email, password, rememberMe, inviteToken) => {
    // TODO: use another endpoint to only alow enterprise users to login
    const response = await axios.post(`/auth/login`, {
      email,
      password
    });
    const { elevatedToken, mobileLast4, twoFaMethod, twoFaEnabled, mobileNumberVerified } = response.data;

    setSession(elevatedToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          mobileNumberVerified
        },
        mobileLast4,
        rememberMe,
        inviteToken,
        twoFaMethod,
        twoFaEnabled
      }
    });
  };

  const switchBusinessProfile = async (selectedEnterpriseConnection) => {
    dispatch({ type: 'SWITCH_BUSINESS_PROFILE', payload: { selectedEnterpriseConnection } });
  };

  const verifyCode = async (code, twoFaMethod = null) => {
    const response = await axios.post(`/banking/enterprise/verify-mobile`, {
      code,
      rememberMe: state.rememberMe,
      inviteToken: state.inviteToken,
      twoFaMethod
    });
    const { bankingToken, requireEmailVerification } = response.data;

    setSession(bankingToken);

    const { user, enterpriseUserConnection, selectedEnterpriseConnection } = await getData();

    dispatch({
      type: 'MFA_CONFIRM',
      payload: {
        user,
        enterpriseUserConnection,
        selectedEnterpriseConnection,
        inviteToken: requireEmailVerification ? state.inviteToken : null
      }
    });

    return {
      requireEmailVerification
    };
  };
  const verifyTotp = async (code) => {
    const response = await axios.post(`/banking/enterprise/verify-totp`, {
      code,
      rememberMe: state.rememberMe,
      inviteToken: state.inviteToken
    });
    const { bankingToken, requireEmailVerification } = response.data;

    setSession(bankingToken);

    const { user, enterpriseUserConnection, selectedEnterpriseConnection } = await getData();

    dispatch({
      type: 'MFA_CONFIRM',
      payload: {
        user,
        enterpriseUserConnection,
        selectedEnterpriseConnection,
        inviteToken: requireEmailVerification ? state.inviteToken : null
      }
    });

    return {
      requireEmailVerification
    };
  };

  const modifyMobile = async (mobile) => {
    try {
      const response = await axios.post('auth/modify-mobile', { mobileNumber: mobile });
      const { mobileLast4 } = response.data;
      if (mobileLast4) {
        dispatch({ type: 'MODIFY_MOBILE', payload: { mobileLast4 } });
        // TODO: handle error
        return { success: true };
      }
    } catch (error) {
      dispatch({ type: 'UPDATE_MESSAGE', payload: { message: error.message } });
      return { success: false, message: error.message };
    }
  };

  const modifyEmail = async (updatedEmail) => {
    try {
      const response = await axios.post('auth/modify-email', { email: updatedEmail });
      const { email } = response.data;

      dispatch({ type: 'MODIFY_EMAIL', payload: { email } });
      // TODO: handle error
      return { success: true };
    } catch (error) {
      // dispatch({ type: 'UPDATE_MESSAGE', payload: { message: error.message } });
      return { success: false, message: error.message };
    }
  };

  const verifyEmail = async (code) => {
    const response = await axios.post(`auth/verify-email`, {
      code,
      inviteToken: state.inviteToken
    });
    const { success } = response.data;

    const { user, enterpriseUserConnection, selectedEnterpriseConnection } = await getData();

    if (success) {
      dispatch({
        type: 'VERIFY_EMAIL_SUCCESS',
        payload: {
          user,
          enterpriseUserConnection,
          selectedEnterpriseConnection
        }
      });
    }
    return { success };
  };

  const createNewEnterpriseProfile = async (nickname, avatar) => {
    try {
      const newProfileResponse = await axios.post('/banking/enterprise/create', {
        nickname
      });
      const { enterpriseProfileId } = newProfileResponse.data;

      if (!enterpriseProfileId) {
        throw new Error('Failed to create new business profile');
      }

      const formData = new FormData();
      formData.append('avatar', avatar);

      const avatarResponse = await axios.post(`/banking/enterprise/${enterpriseProfileId}/avatar`, formData);

      if (!avatarResponse.data.success) {
        throw new Error('Failed to upload avatar');
      }

      await initialize();
    } catch (err) {
      console.error(err);
    }
  };

  const register = async (email, password, twoFaMethod, inviteToken) => {
    const response = await axios.post('/auth/register-admin', {
      email,
      password,
      twoFaMethod,
      sendEmailVerificationCode: true
    });
    const { elevatedToken, mobileLast4, twoFaEnabled, mobileNumberVerified } = response.data;

    setSession(elevatedToken);
    // window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user: { mobileNumberVerified },
        mobileLast4,
        inviteToken,
        twoFaMethod,
        twoFaEnabled
      }
    });
  };

  const requestAffiliateProgram = async (linkSlug) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/affiliate-program/request`,
      {
        linkSlug
      }
    );
    const { success } = response.data;

    if (success) {
      await initialize();
    }

    return {
      success
    };
  };

  const editAffiliateLink = async (linkSlug) => {
    const response = await axios.put(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/affiliate-program/affiliate-link/edit`,
      {
        linkSlug
      }
    );
    const { code, message, success } = response.data;

    if (success) {
      await initialize();
    }

    if (code === 409) {
      throw new Error(message);
    }

    return {
      success
    };
  };

  const getAffiliateData = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/affiliate-program/details`
    );

    return response?.data || {};
  };

  const getAffiliatedMontlyPayoutDate = async (monthString) => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/affiliate-program/monthly-payout/${monthString}`
    );
    return response?.data || {};
  };

  const getAccountInfo = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/account/info`
    );
    return response?.data || {};
  };

  const getAccounts = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/accounts`
    );
    return response?.data || {};
  };

  const getAccountActivity = async (accountType, page, type, search = '') => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/account/${accountType}/activity?page=${page}&type=${type}&search=${search}`
    );
    return response?.data || {};
  };

  const getCreators = async (page, enterpriseAccountConnectionStatus, search = '') => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/creators?page=${page}&enterpriseAccountConnectionStatus=${enterpriseAccountConnectionStatus}&search=${search}`
    );
    return response?.data || {};
  };

  const getCreatorDetails = async (accountConnectionId) => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/creator/${accountConnectionId}/details`
    );
    return response?.data || {};
  };

  const getCreatorPayouts = async (accountConnectionId, page, search = '') => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/creator/${accountConnectionId}/payouts?page=${page}&search=${search}`
    );
    return response?.data || {};
  };

  const getCreatorPersonalInfo = async (accountConnectionId) => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/creator/${accountConnectionId}/personal-info`
    );
    return response?.data || {};
  };

  const getEnterprisePayouts = async (page, search = '') => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/payouts?page=${page}&search=${search}`
    );
    return response?.data || {};
  };

  const createPayout = async (accountConnectionId, amount, description) => {
    const amountInCents = parseFloat(amount) * 100;
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/creator/${accountConnectionId}/payout`,
      {
        amountInCents,
        description
      }
    );
    return response?.data || {};
  };

  const requestApiAccess = async ({ agreed }) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/api/request`,
      { agreed }
    );
    const { success } = response.data;

    if (success) {
      await initialize();
    }

    return {
      success
    };
  };

  const generateApiKey = async (accessLevel) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/api/generate-key`,
      { accessLevel }
    );

    return response?.data || {};
  };

  const getApiKeys = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/api/keys`
    );
    return response?.data || {};
  };

  const revokeKey = async (apiKeyId, password) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/api/revoke-key/${apiKeyId}`,
      {
        password
      }
    );
    return response?.data || {};
  };

  const checkEnterpriseProfileApplicationStatus = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/application-status`
    );
    const { status } = response.data;
    if (status !== 'created' && status !== 'pending' && status !== 'processing') {
      await initialize();
    } else {
      setTimeout(checkEnterpriseProfileApplicationStatus, 2000);
    }
    return response?.data || {};
  };

  const checkPayPalApplicationStatus = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/paypal/application-status`
    );
    const { status } = response.data;
    if (status && status !== 'created' && status !== 'pending' && status !== 'processing') {
      await initialize();
      return { status, ready: true };
    }
    setTimeout(checkPayPalApplicationStatus, 2000);
    return response?.data || {};
  };

  const openCheckingAccount = async () => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/account/open`
    );

    const { success } = response.data;

    if (success) {
      await initialize();
    }

    return response?.data || {};
  };

  const openPayPalAccount = async () => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/paypal/open`
    );

    const { success } = response.data;

    if (success) {
      await initialize();
    }

    return response?.data || {};
  };

  const checkAccountApplicationStatus = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/account/application-status`
    );

    const { status, pendingPayoutsApplicationStatus } = response.data;
    if (
      (status === 'approved' || status === 'rejected') &&
      (pendingPayoutsApplicationStatus === 'approved' || pendingPayoutsApplicationStatus === 'rejected')
    ) {
      await initialize();
    } else {
      setTimeout(checkAccountApplicationStatus, 2000);
    }
    return response?.data || {};
  };

  const lookupAccountConnections = async (users) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/account-connections/lookup`,
      { users }
    );
    return response?.data || {};
  };

  const sendBulkPayments = async (payments, fileName, customDescription) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/bulk-payments`,
      { batch: payments, fileName, customDescription }
    );
    return response?.data || {};
  };

  const getBatchResult = async (batchId) => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/bulk-payments/${batchId}`
    );
    return response?.data || {};
  };

  const getBulkPaymentsHistory = async (page) => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/bulk-payments?page=${page}`
    );
    return response?.data || {};
  };
  const getBulkDetails = async (bulkId, page) => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/bulk-payments/details/${bulkId}?page=${page}`
    );
    return response?.data || {};
  };

  const logout = async () => {
    await axios.post('/auth/logout');
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const forgotPassword = async (email) => {
    await axios.post('/auth/forgot-password', { email });
  };
  const resetPassword = async ({ email, password, code }) => {
    await axios.post(`/auth/reset-password`, { email, password, code });
  };

  const updateProfile = () => {};

  const inviteUser = async (email, accessLevel) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/user-connection/create`,
      { email, ...accessLevel }
    );
    return response?.data || {};
  };

  const getUsers = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/user-connections`
    );
    return response?.data || {};
  };

  const updateUser = async (userConnectionId, accessLevel) => {
    const response = await axios.put(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/user-connection/${userConnectionId}/update`,
      accessLevel
    );
    return response?.data || {};
  };

  const updateDetails = async (accountConnectionId, details) => {
    const response = await axios.put(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/creator/${accountConnectionId}/details/update`,
      {
        details
      }
    );

    return response?.data || {};
  };

  const inviteCreator = async ({
    firstName,
    lastName,
    email,
    businessName,
    permissions,
    verificationPrompt,
    verificationAnswer,
    legacyPayoutMethod
  }) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/creator/invite`,
      {
        firstName,
        lastName,
        email,
        businessName,
        requestedPermissions: permissions,
        verificationPrompt,
        verificationAnswer,
        legacyPayoutMethod
      }
    );

    const { message, success, accountConnectionId } = response.data;

    if (success) {
      await initialize();
    }

    if (!success) {
      throw new Error(message);
    }
    return { success, accountConnectionId };
  };

  const lookupCreators = async (users, shouldUpdateAndReinvite) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/creators/lookup`,
      { users, shouldUpdateAndReinvite }
    );
    return response?.data || {};
  };

  const sendBulkInvites = async (creators, permissions) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/creators/bulk-invites`,
      { creators, requestedPermissions: permissions }
    );
    return response?.data || {};
  };
  const getRedirect = () => {
    const DO_NOT_REDIRECT_TO = [
      '/auth/login',
      '/auth/register',
      '/auth/register-unprotected',
      '/auth/reset-password',
      '/auth/verify',
      '/auth/verify-email'
    ];
    const url = state.redirectUrl;
    dispatch({ type: 'REDIRECT' });
    return DO_NOT_REDIRECT_TO.includes(url) ? '/' : url;
  };
  const setRedirect = (redirectUrl) => {
    dispatch({
      type: 'SET_REDIRECT_URL',
      payload: {
        redirectUrl
      }
    });
  };

  const getIntegrationConfig = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/integration/config`
    );
    return response?.data || {};
  };

  const savePayPalConfig = async (config) => {
    const response = await axios.put(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/integration/config`,
      {
        config
      }
    );
    return response?.data || {};
  };

  const downloadCsv = async (accountType) => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/${accountType}/csv`,
      {
        responseType: 'blob'
      }
    );
    return response?.data || {};
  };

  const getPayoutDetails = async (payoutId) => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/payout/${payoutId}/details`
    );
    return response?.data || {};
  };

  const getRelatedTransfer = async (transferId, type) => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/transfer/${type}/${transferId}/details`
    );
    return response?.data || {};
  };

  const requestDashboardPayoutAccess = async ({ agreed }) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/dashboard-payout/request`,
      { agreed }
    );
    const { success } = response.data;

    if (success) {
      await initialize();
    }

    return {
      success
    };
  };

  const updateNotificationSettings = async (settings) => {
    const response = await axios.put(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/${state.selectedEnterpriseConnection.id}/noticivation-settings/update`,
      { settings }
    );
    const { success } = response.data;

    if (success) {
      await initialize();
    }

    return {
      success
    };
  };

  const resendPayout = async (payoutId) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/payout/${payoutId}/resend`
    );
    return response?.data || {};
  };

  const checkDomainWhitelist = async () => {
    const response = await axios.get(`/banking/enterprise/whitelist-check`);
    return response?.data || {};
  };

  const getCorporateWireCounterpartyDetails = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/corporate-counterparty`
    );
    return response?.data || {};
  };

  const wireToCorporateWireCounterparty = async (amountInCents) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/corporate-wire`,
      { amountInCents }
    );
    return response?.data || {};
  };

  const achToCorporateCounterparty = async (amountInCents) => {
    const response = await axios.post(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/corporate-ach`,
      { amountInCents }
    );
    return response?.data || {};
  };

  const getPendingWireAmount = async () => {
    const response = await axios.get(
      `/banking/enterprise/${state.selectedEnterpriseConnection.enterpriseProfile.id}/pending-wire-amount`
    );
    return response?.data || {};
  };
  const getTwoFaQrCode = async () => {
    const response = await axios.get(`/auth/totp/code`);
    return response?.data || {};
  };
  const setupTwoFa = async (code) => {
    const response = await axios.post(`/auth/totp/setup`, { code, inviteToken: state.inviteToken });

    const { bankingToken, requireEmailVerification } = response.data;

    setSession(bankingToken);

    const { user, enterpriseUserConnection, selectedEnterpriseConnection } = await getData();

    dispatch({
      type: 'MFA_CONFIRM',
      payload: {
        user,
        enterpriseUserConnection,
        selectedEnterpriseConnection,
        inviteToken: requireEmailVerification ? state.inviteToken : null
      }
    });

    return {
      requireEmailVerification
    };
  };
  const changePhoneNumberForLoggedInUser = async (mobileNumber) => {
    const response = await axios.post(`/auth/mobile/change-number`, { mobileNumber });
    dispatch({ type: 'SET_NEW_MOBILE_NUMBER', payload: { newMobileNumber: mobileNumber } });
    return response?.data || {};
  };

  const verifyPhoneNumberForLoggedInUser = async (code) => {
    const response = await axios.post(`/auth/mobile/verify-for-change`, { code });
    const { success } = response?.data;

    if (success) {
      dispatch({ type: 'UPDATE_USER_PHONE' });
    }
    return response?.data || {};
  };

  const updateMfaMethod = async (method) => {
    dispatch({
      type: 'UPDATE_MFA_METHOD',
      payload: {
        twoFaMethod: method
      }
    });
  };

  const changePassword = async (password, newPassword) => {
    const response = await axios.post(`/auth/change-password`, { password, newPassword });
    return response?.data || {};
  };

  const changeEmailForLoggedInUser = async (email) => {
    const response = await axios.post(`/auth/email/change-email`, { email });
    return response?.data || {};
  };

  const verifyChangeEmailForLoggedInUser = async (code) => {
    const response = await axios.post(`/auth/email/verify-for-change`, { code });
    return response?.data || {};
  };

  const updateEmailDone = async (email) => {
    dispatch({
      type: 'UPDATE_EMAIL',
      payload: {
        email
      }
    });
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        switchBusinessProfile,
        verifyCode,
        logout,
        register,
        forgotPassword,
        resetPassword,
        updateProfile,
        createNewEnterpriseProfile,
        verifyEmail,
        modifyMobile,
        modifyEmail,
        requestAffiliateProgram,
        getAffiliateData,
        getAffiliatedMontlyPayoutDate,
        editAffiliateLink,
        getAccountInfo,
        getAccounts,
        getAccountActivity,
        requestApiAccess,
        generateApiKey,
        getApiKeys,
        revokeKey,
        checkAccountApplicationStatus,
        checkEnterpriseProfileApplicationStatus,
        checkPayPalApplicationStatus,
        openCheckingAccount,
        openPayPalAccount,
        getCreators,
        getCreatorDetails,
        getCreatorPayouts,
        getEnterprisePayouts,
        lookupAccountConnections,
        sendBulkPayments,
        getBatchResult,
        createPayout,
        getBulkPaymentsHistory,
        getBulkDetails,
        inviteUser,
        getUsers,
        updateUser,
        updateDetails,
        inviteCreator,
        lookupCreators,
        sendBulkInvites,
        getRedirect,
        setRedirect,
        getIntegrationConfig,
        savePayPalConfig,
        getCreatorPersonalInfo,
        downloadCsv,
        getPayoutDetails,
        getRelatedTransfer,
        requestDashboardPayoutAccess,
        updateNotificationSettings,
        resendPayout,
        checkDomainWhitelist,
        getCorporateWireCounterpartyDetails,
        wireToCorporateWireCounterparty,
        getPendingWireAmount,
        achToCorporateCounterparty,
        getTwoFaQrCode,
        setupTwoFa,
        verifyTotp,
        changePhoneNumberForLoggedInUser,
        verifyPhoneNumberForLoggedInUser,
        updateMfaMethod,
        changePassword,
        changeEmailForLoggedInUser,
        verifyChangeEmailForLoggedInUser,
        updateEmailDone
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
