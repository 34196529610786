import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';
import useAuth from '../hooks/useAuth';
import { isProd } from '../config';

// ----------------------------------------------------------------------
const configSidebarForEnv = (config) => {
  const env = process.env.NODE_ENV;
  return config
    .filter((item) => !item.enviroment || item.enviroment.includes(env))
    .map((c) => ({
      ...c,
      children: c.children ? configSidebarForEnv(c.children) : []
    }));
};

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { selectedEnterpriseConnection } = useAuth();

  const accessLevel = [];
  const renderIfFlags = [];

  if (selectedEnterpriseConnection.isAdmin) {
    accessLevel.push('isAdmin');
  }
  if (selectedEnterpriseConnection.isDeveloper) {
    accessLevel.push('isDeveloper');
  }
  if (selectedEnterpriseConnection.hasBankingAccess) {
    accessLevel.push('hasBankingAccess');
  }

  const hasDashPayoutAccess = selectedEnterpriseConnection?.enterpriseProfile?.dashboardPayoutStatus === 'active';
  // console.log('selectedEnterpriseConnection, router', hasDashPayoutAccess, selectedEnterpriseConnection);

  if (hasDashPayoutAccess) {
    renderIfFlags.push('hasDashPayoutAccess');
  } else {
    renderIfFlags.push('hasNoDashPayoutAccess');
  }

  // const dashboardPayoutStatus = selectedEnterpriseConnection?.enterpriseProfile?.dashboardPayoutStatus;
  // console.log('selectedEnterpriseConnection, router', hasDashPayoutAccess, selectedEnterpriseConnection);

  // if (dashboardPayoutStatus === 'active') {
  //   renderIfFlags.push('hasDashPayoutAccess');
  // } else if (dashboardPayoutStatus !== 'disabled') {
  //   renderIfFlags.push('hasNoDashPayoutAccess');
  // }

  const filterAccessLevel = (configs) =>
    configs
      .filter((config) => {
        const shouldCheckOtherConditions =
          !config?.renderIf?.Or ||
          config.renderIf.Or.length === 0 ||
          renderIfFlags.some((access) => config.renderIf.Or.includes(access));

        if (!shouldCheckOtherConditions) {
          return false;
        }
        if (!isProd) {
          return true;
        }
        if (!config.grantAccessTo) {
          return true;
        }
        return accessLevel.some((access) => config.grantAccessTo.includes(access));
      })
      .map((c) => ({
        ...c,
        children: c.children ? filterAccessLevel(c.children) : []
      }));

  return useRoutes(
    // ! if changing enviroment attribute, change it in SidebarConfig.js too
    filterAccessLevel(
      configSidebarForEnv([
        {
          path: 'auth',
          children: [
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <Login />
                </GuestGuard>
              )
            },
            {
              path: 'register',
              element: (
                <GuestGuard>
                  <Register />
                </GuestGuard>
              )
            },
            { path: 'login-unprotected', element: <Login /> },
            { path: 'register-unprotected', element: <Register /> },
            { path: 'reset-password', element: <ResetPassword /> },
            { path: 'verify', element: <VerifyCode /> },
            { path: 'verify-email', element: <VerifyEmail /> }
          ]
        },
        { path: 'invite/:token', element: <AcceptTokenPage /> },

        // Dashboard Routes
        {
          path: '/',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: <Navigate to="/enterprise" replace />
            },
            {
              path: 'enterprise',
              children: [
                {
                  element:
                    selectedEnterpriseConnection.isAdmin || selectedEnterpriseConnection.hasBankingAccess ? (
                      <Navigate to="/enterprise/overview" replace />
                    ) : (
                      <Navigate to="/connect/api" replace />
                    )
                },
                {
                  path: 'overview',
                  element: <AccountOverview />,
                  grantAccessTo: ['isAdmin', 'hasBankingAccess']
                },
                { path: 'users', element: <EnterpriseUser />, grantAccessTo: ['isAdmin'] },
                // {
                //   path: 'affiliate-program',
                //   element: <AffiliateProgram />,
                //   enviroment: ['production'],
                //   grantAccessTo: ['isAdmin', 'hasBankingAccess']
                // },
                { path: 'terms', element: <AffiliateTerms /> },
                {
                  path: 'overview/:accountType/activity',
                  element: <AccountActivity />,
                  grantAccessTo: ['isAdmin', 'hasBankingAccess']
                }
              ]
            },
            {
              path: 'connect',
              children: [
                {
                  element:
                    selectedEnterpriseConnection.isAdmin || selectedEnterpriseConnection.hasBankingAccess ? (
                      hasDashPayoutAccess ? (
                        <Navigate to="/connect/creators" replace />
                      ) : (
                        <Navigate to="/connect/request-payout" replace />
                      )
                    ) : (
                      <Navigate to="/connect/api" replace />
                    )
                },

                {
                  path: 'creators',
                  children: [
                    // { element: <Navigate to="/connect/creators/list" replace /> },
                    {
                      path: '',
                      element: <CreatorsListPage />
                    },
                    {
                      path: 'invite',
                      element: <InviteCreatorPage />
                    },
                    {
                      path: 'bulk-invites',
                      element: <BulkInvitePage />
                    },
                    {
                      path: 'payout/:payoutId',
                      element: <PayoutDetails />
                    },
                    {
                      path: 'details/:creatorId',
                      children: [
                        {
                          path: '',
                          element: <CreatorsDetailsPage />
                        },
                        {
                          path: 'personal-info',
                          element: <ViewPersonalInfo />
                        }
                      ]
                    }
                  ],
                  grantAccessTo: ['isAdmin', 'hasBankingAccess'],
                  renderIf: {
                    Or: ['hasDashPayoutAccess']
                  }
                },
                {
                  path: 'bulk-payments',
                  children: [
                    {
                      path: '',
                      element: <BulkPaymentsPage />
                    },
                    {
                      path: 'details/:batchId',
                      element: <BulkPaymentDetailsPage />
                    },
                    {
                      path: 'history',
                      element: <BulkPaymentsHistoryPage />
                    }
                  ],
                  grantAccessTo: ['isAdmin', 'hasBankingAccess'],
                  renderIf: {
                    Or: ['hasDashPayoutAccess']
                  }
                },

                {
                  path: 'request-payout',
                  element: <RequestDashboardPayoutAccess />,
                  grantAccessTo: ['isAdmin', 'hasBankingAccess'],
                  renderIf: {
                    Or: ['hasNoDashPayoutAccess']
                  }
                },
                { path: 'api', element: <ApiPage />, grantAccessTo: ['isAdmin', 'isDeveloper'] },
                { path: 'integrations', element: <IntegrationsPage />, grantAccessTo: ['isAdmin', 'hasBankingAccess'] }
              ]
            },
            {
              path: 'settings',
              children: [
                {
                  element: <Navigate to="/settings/notifications" replace />
                },

                {
                  path: 'notifications',
                  element: <NotificationSettings />,
                  grantAccessTo: ['isAdmin', 'hasBankingAccess']
                }
              ],
              grantAccessTo: ['isAdmin', 'hasBankingAccess']
            },
            {
              path: 'account-settings',
              children: [
                {
                  element: <Navigate to="/account-settings/two-factor-authentication" replace />
                },

                {
                  path: '/account-settings/two-factor-authentication',
                  element: <Security />
                }
              ]
            }
          ]
        },
        {
          path: 'user/new',
          element: (
            <AuthGuard>
              <UserCreate />
            </AuthGuard>
          )
        },
        // Main Routes
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: 'maintenance', element: <Maintenance /> },
            { path: '500', element: <Page500 /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" replace /> }
          ]
        },
        { path: '*', element: <Navigate to="/404" replace /> }
      ])
    )
  );
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const VerifyEmail = Loadable(lazy(() => import('../pages/authentication/VerifyEmail')));

// Enterprise
const AffiliateProgram = Loadable(lazy(() => import('../pages/enterprise/AffiliateProgram')));
const AccountOverview = Loadable(lazy(() => import('../pages/enterprise/Account')));
const AccountActivity = Loadable(lazy(() => import('../pages/enterprise/AccountActivity')));
const AffiliateTerms = Loadable(lazy(() => import('../pages/enterprise/AffiliateTerms')));
const EnterpriseUser = Loadable(lazy(() => import('../pages/enterprise/EnterpriseUserPage')));

// Invite
const AcceptTokenPage = Loadable(lazy(() => import('../pages/enterprise/AcceptTokenPage')));

// Connect
const CreatorsListPage = Loadable(lazy(() => import('../pages/enterprise/Creators')));
const CreatorsDetailsPage = Loadable(lazy(() => import('../pages/enterprise/CreatorDetails')));
const ViewPersonalInfo = Loadable(lazy(() => import('../pages/enterprise/ViewPersonalInfo')));
const InviteCreatorPage = Loadable(lazy(() => import('../pages/enterprise/InviteCreator')));
const BulkInvitePage = Loadable(lazy(() => import('../pages/enterprise/BulkInvite')));
const BulkPaymentsPage = Loadable(lazy(() => import('../pages/enterprise/BulkPayments')));
const BulkPaymentsHistoryPage = Loadable(lazy(() => import('../pages/enterprise/BulkPaymentsHistory')));
const BulkPaymentDetailsPage = Loadable(lazy(() => import('../pages/enterprise/BulkPaymentDetails')));
const ApiPage = Loadable(lazy(() => import('../pages/enterprise/ApiPage')));
const IntegrationsPage = Loadable(lazy(() => import('../pages/enterprise/IntegrationsPage')));
const PayoutDetails = Loadable(lazy(() => import('../pages/enterprise/PayoutDetails')));
const RequestDashboardPayoutAccess = Loadable(lazy(() => import('../pages/enterprise/RequestDashboardPayoutAccess')));
// My Account
const NotificationSettings = Loadable(lazy(() => import('../pages/enterprise/NotificationSettings')));
const Security = Loadable(lazy(() => import('../pages/enterprise/Security')));

// Dashboard
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// Main
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
