import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isSentMfaToken, user } = useAuth();

  if (!isAuthenticated && isSentMfaToken) {
    // console.log('verify code');
    // console.log('requestedLocation', requestedLocation);
    // navigate('/auth/verify');
    return <Navigate to="/auth/verify" />;
  }

  if (user && !user.emailVerified) {
    return <Navigate to="/auth/verify-email" />;
  }

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
